import { ILocationWithDetailsModel, TServiceTypeModel } from '../../../@generated/webExpApi';
import { showIsLocationTemporarilyUnavailable } from '../../../lib/getFeatureFlags';
import isLocationClosed from '../../../lib/locations/isLocationClosed';
import resolveOpeningHours from '../../../lib/locations/resolveOpeningHours';
import { IGetOpeningHoursInfo } from './types';

export const LOCATION_STATUS_TYPE = {
    TemporarilyUnavailable: 'TemporarilyUnavailable',
    StoreHoursOpened: 'StoreHoursOpened',
    StoreHoursClosed: 'StoreHoursClosed',
    Open24h: 'Open24h',
    PermanentlyOrTemporarilyClosed: 'PermanentlyOrTemporarilyClosed',
};

// This function and getStoreStatus are absolutely identical, the reason why there's two of them is that because
// the exp api endpoint for location details pages data has different data contract
// TODO unify data contracts for all location endpoints and remove the duplicates in the logic
export const getOpeningHoursInfo: IGetOpeningHoursInfo = (location: ILocationWithDetailsModel) => {
    const resolvedOpeningHours = resolveOpeningHours(location, TServiceTypeModel.Store);
    const isClosed = isLocationClosed(location);

    if (isClosed) {
        return {
            status: LOCATION_STATUS_TYPE.PermanentlyOrTemporarilyClosed,
            isOpen: false,
            statusText: 'Closed',
        };
    }

    if (showIsLocationTemporarilyUnavailable() && location.additionalFeatures?.isOffline) {
        return {
            status: LOCATION_STATUS_TYPE.TemporarilyUnavailable,
            isOpen: resolvedOpeningHours?.isOpen,
            statusText: 'Temporarily Unavailable',
            shouldHighLight: true,
        };
    }

    if (!resolvedOpeningHours) {
        return null;
    }

    const { isOpen, openTime, closeTime, isTwentyFourHourService, isClosingSoon } = resolvedOpeningHours;

    if (isOpen && isTwentyFourHourService) {
        return {
            status: LOCATION_STATUS_TYPE.Open24h,
            isOpen,
            statusText: 'Open 24H',
        };
    }

    if (isOpen) {
        return {
            status: LOCATION_STATUS_TYPE.StoreHoursOpened,
            isOpen,
            statusText: `Open Now - Closes ${closeTime}`,
            shouldHighLight: !!isClosingSoon,
        };
    }

    return {
        status: LOCATION_STATUS_TYPE.StoreHoursClosed,
        isOpen,
        statusText: `Closed Now - Opens ${openTime}`,
    };
};
