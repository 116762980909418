import React from 'react';
import Button from '@material-ui/core/Button';
import MenuList from '@material-ui/core/MenuList';
import styles from './index.module.css';
import Icon, { IconSize } from '../BrandIcon';
import classnames from 'classnames';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Popper, { PopperPlacementType } from '@material-ui/core/Popper';

const SelectOptionsList = ({ children, ...props }) => {
    return <MenuList {...props}>{children}</MenuList>;
};

interface IDropdown {
    label?: string;
    className?: string;
    disabled?: boolean;
    active?: boolean;
    labelClassName?: string;
    listClassName?: string;
    optionsListClassName?: string;
    buttonClassName?: string;
    children?: JSX.Element[] | string[];
    target?: JSX.Element | string;
    onClose?: () => void;
    popperPlacement?: PopperPlacementType;
    popperClass?: string;
    flipEnabled?: boolean;
    iconSize?: IconSize;
}

export default function Dropdown(props: IDropdown): JSX.Element {
    const {
        label,
        className,
        disabled,
        active,
        labelClassName,
        listClassName,
        optionsListClassName,
        buttonClassName,
        children,
        target,
        onClose,
        popperPlacement = 'bottom',
        popperClass,
        flipEnabled = false,
        iconSize = 'xs',
    } = props;
    const [open, setOpen] = React.useState(false);

    const anchorRef = React.useRef<HTMLButtonElement>(null);

    const toggleOpen = () => {
        if (open) {
            if (typeof onClose === 'function') {
                onClose();
            }
        }
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = () => {
        if (typeof onClose === 'function') {
            onClose();
        }

        setOpen(false);
    };

    return (
        <div className={classnames(styles.container, { [className]: !!className })}>
            {label && <span className={classnames(styles.label, { [labelClassName]: !!labelClassName })}>{label}</span>}
            <ClickAwayListener onClickAway={handleClose}>
                <div className={styles.wrapper}>
                    <Button
                        ref={anchorRef}
                        aria-haspopup="true"
                        onClick={toggleOpen}
                        onKeyDown={({ key }) => key === 'Escape' && handleClose()}
                        className={classnames('t-paragraph', styles.button, {
                            [styles.active]: !!active,
                            [buttonClassName]: !!buttonClassName,
                        })}
                        disabled={disabled}
                        disableRipple
                    >
                        <span className={classnames('truncate', styles.buttonText)}>{target}</span>
                        <Icon size={iconSize} icon={open ? 'direction-up' : 'direction-down'} />
                    </Button>
                    <Popper
                        open={open}
                        placement={popperPlacement}
                        anchorEl={anchorRef.current}
                        modifiers={{
                            flip: {
                                enabled: flipEnabled,
                            },
                            preventOverflow: {
                                enabled: false,
                            },
                            hide: {
                                enabled: false,
                            },
                        }}
                        disablePortal
                        className={classnames(styles.popper, { [popperClass]: !!popperClass })}
                    >
                        <Paper className={classnames(styles.paper, { [listClassName]: !!listClassName })}>
                            <SelectOptionsList
                                onClick={handleClose}
                                onKeyDown={({ key }) => key === 'Enter' && handleClose()}
                                className={classnames({ [optionsListClassName]: !!optionsListClassName })}
                            >
                                {children}
                            </SelectOptionsList>
                        </Paper>
                    </Popper>
                </div>
            </ClickAwayListener>
        </div>
    );
}
