import React, { FC, Fragment } from 'react';

const TextWithDotSeparator: FC<{
    leftText?: JSX.Element | string;
    rightText?: JSX.Element | string;
    texts?: Array<JSX.Element | string>;
}> = ({ leftText, rightText, texts }) => {
    if (texts) {
        return (
            <Fragment>
                {texts.map((text, index) => (
                    <Fragment key={index}>
                        {text}
                        {index < texts.length - 1 ? <>&nbsp;&nbsp;•&nbsp;&nbsp;</> : null}
                    </Fragment>
                ))}
            </Fragment>
        );
    }
    if (leftText && rightText) {
        return (
            <Fragment>
                {leftText}&nbsp;&nbsp;•&nbsp;&nbsp;{rightText}
            </Fragment>
        );
    }

    return <Fragment>{leftText || rightText}</Fragment>;
};

export default TextWithDotSeparator;
