import React from 'react';

interface ITabPanel extends React.HTMLAttributes<HTMLDivElement> {
    index: number;
    value: number;
}

function InspireTabPanel(props: ITabPanel): JSX.Element {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} {...other}>
            {children}
        </div>
    );
}

export default InspireTabPanel;
