import { isLocationPagesOn } from '../../lib/getFeatureFlags';
import { LocationWithDetailsModel } from '../services/locationService/types';

const getLocationDetailsPageUrl = (location: LocationWithDetailsModel): string | null => {
    const rioUrl = process.env.NEXT_PUBLIC_RIO_LOCATIONS_DOMAIN;

    if (location?.id) {
        if (isLocationPagesOn() && location.url) {
            return `/locations/${location.url}`;
        } else if (rioUrl !== 'undefined') {
            return new URL(`locationId/${location.id}`, rioUrl).href;
        }
    }

    return null;
};

export default getLocationDetailsPageUrl;
