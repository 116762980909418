import React, { FC } from 'react';
import Tabs, { TabsProps } from '@material-ui/core/Tabs';
import classNames from 'classnames';
import styles from './tabset.module.css';

interface IInspireTabSetProps extends TabsProps {
    className?: string;
    disabled?: boolean;
}

const InspireTabSet: FC<IInspireTabSetProps> = (props: TabsProps): JSX.Element => {
    const { children, classes, className, disabled, ...rest } = props;
    const inspireClasses = {
        ...classes,
        root: classNames(styles.tabs, className),
        indicator: classNames({
            [styles.tabIndicator]: !disabled,
            [styles.disabled]: disabled,
        }),
    };

    return (
        <Tabs classes={inspireClasses} {...rest}>
            {children}
        </Tabs>
    );
};

export default InspireTabSet;
