import React, { FC } from 'react';
import Tab, { TabProps } from '@material-ui/core/Tab';
import classNames from 'classnames';

import styles from './tabset.module.css';

interface IInspireTabProps extends TabProps {
    className?: string;
    disabled?: boolean;
}

const InspireTab: FC<IInspireTabProps> = (props): JSX.Element => {
    const { classes, className, disabled, ...rest } = props;
    const inspireClasses = {
        ...classes,
        root: classNames(styles.tab, 't-subheader-small', className),
        wrapper: classNames(styles.tabWrapper, { [styles.withIcon]: !!rest.icon }),
        textColorInherit: styles.inactiveTab,
        indicator: classNames({
            [styles.disabled]: disabled,
        }),
    };

    return <Tab {...rest} classes={inspireClasses} disabled={disabled} />;
};

export default InspireTab;
